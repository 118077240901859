.App {
  text-align: center;
}

a{
  text-decoration: none !important;
}
.mouse-hover:hover{
    cursor: pointer;
  }

.flex-center{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-start{
  display: flex;
  align-items: center;
  justify-content:flex-start;
}
.text-width25{
  min-width: 25%;
  max-width: 25%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-width30{
  min-width: 25%;
    max-width: 30%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
    padding-left: 4px;
}
.text-width50{
  min-width: 25%;
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  padding-right: 5px;
  padding-left: 15px;
}
.font-white{
  color: white;
}

.height20{
  min-height:20px;
  max-height: 20px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove spinner for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
