.flex{
    display: flex;
    flex-direction: row;
}

.flex-col{
    flex-direction: column;
}

.align-items-center{
    justify-content: center;
    align-items: center;
}